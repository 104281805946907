import styled, { css } from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Grid } from "@mui/material"
import CustomLink from "../../components/custom-link/custom-link.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import { ArrowBack } from "@mui/icons-material"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 1em;
  }
`
export const Wrapper = styled(Container)``
export const TitleWrapper = styled.div`
  max-width: 893px;
  margin-top: 0;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 0;
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
`

export const Link = styled(CustomLink)`
  margin-right: 1em;
  display: flex;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  border-radius: 50%;
  transition: all 0.3s ease;

  &:hover {
    background-color: black;
    svg {
      color: white;
    }
  }
`

export const ArrowBackIcon = styled(ArrowBack)`
  color: black;
`

export const ContentWrapper = styled.div`
  padding-top: 1em;
  margin-top: 1em;
  padding-bottom: 1em;
  border-top: 1px solid rgba(0, 0, 0, 0.4);

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 4em;
    padding-bottom: 4em;
  }
`

export const SideElement = styled.div`
  margin-top: 6em;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 1em;
  }
`

export const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: ${({ theme }) => theme.typography.pxToRem(22)};
  font-weight: 400;
  margin: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }
`

export const Title = styled.h2`
  margin: 0;
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;

  font-family: ${({ theme }) => theme.fonts.secondary};

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 16px;
    font-size: ${({ theme }) => theme.typography.pxToRem(40)};
    line-height: ${({ theme }) => theme.typography.pxToRem(44)};
  }
`

export const ButtonGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

export const ApplyButton = styled(CustomButton)`
  border: 1px solid #686868 !important;
`

export const Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.main};

  p,
  ul,
  li {
    span {
      font-weight: 400;
      margin-top: 0;
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      line-height: ${({ theme }) => theme.typography.pxToRem(41)};
      margin-bottom: 2em;
      color: ${({ theme }) => theme.palette.primary.main};
    }
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  h2 {
    font-size: ${({ theme }) => theme.typography.pxToRem(40)};
    line-height: ${({ theme }) => theme.typography.pxToRem(40)};
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.primary};
    letter-spacing: 0.1px;
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    p,
    ul,
    li {
      font-size: 1rem;
      line-height: 2rem;
    }
    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    h3 {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
`

export const ApplyMessage = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
`
