import React, { useContext } from "react"
import FadeTitle from "../../components/fade-title/fade-title.component"
import Box from "@mui/material/Box"
import { DrawerContext } from "../../provider"
import { Grid } from "@mui/material"
import parse from "html-react-parser"

import * as S from "./careers-description.styles"

const CareersDescription = ({ title, description, careers }) => {
  const { division, location } = careers
  const { setIsFormApplyOpen } = useContext(DrawerContext)

  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        {title && (
          <S.TitleWrapper>
            <S.Link url="/vacantes">
              <S.ArrowBackIcon />
            </S.Link>
            <FadeTitle title={title} />
          </S.TitleWrapper>
        )}
        <S.ContentWrapper>
          <Grid container spacing={{ xs: 1, sm: 2, md: 4 }}>
            <Grid item xs={12} md={3}>
              {location && (
                <div>
                  <S.Subtitle>Ubicación</S.Subtitle>
                  <S.Title> {location}</S.Title>
                </div>
              )}
              {division && (
                <S.SideElement>
                  <S.Subtitle>Area</S.Subtitle>
                  <S.Title>{division}</S.Title>
                </S.SideElement>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {description && (
                <S.Description>{parse(description)}</S.Description>
              )}
              <S.ApplyMessage>
                <p>Las postulaciones sin portafolio no seran consideradas.</p>
              </S.ApplyMessage>
              <div>
                <S.ApplyButton
                  className="darkBorder"
                  onClick={() => setIsFormApplyOpen(true)}
                >
                  Postula Ahora
                </S.ApplyButton>
              </div>
            </Grid>
            <S.ButtonGrid item md={3}>
              <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <S.ApplyButton
                  className="darkBorder"
                  onClick={() => setIsFormApplyOpen(true)}
                >
                  Postula Ahora
                </S.ApplyButton>
              </Box>
            </S.ButtonGrid>
          </Grid>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Section>
  )
}

export default CareersDescription
