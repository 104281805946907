import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import HeroBanner from "../../layouts/HeroBanner"
import { getCaseStudyLayout } from "../../get-case-study-layout-utils"
import CareersDescription from "../../layouts/CareersDescription"

export const query = graphql`
  query CareerQuery($id: String!) {
    wpCareer(id: { eq: $id }) {
      title
      date
      content
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      id
      uri
      translation {
        englishUrl
      }
      careers {
        division
        fieldGroupName
        location
      }
    }
  }
`

const CaseStudy = ({ data }) => {
  const { seo, title, content, featuredImage, date, careers, translation } =
    data.wpCareer
  return (
    <Layout seo={seo} englishUrl={translation?.englishUrl}>
      <CareersDescription
        title={title}
        description={content}
        careers={careers}
      />
    </Layout>
  )
}

export default CaseStudy
